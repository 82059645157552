

const InTransit = ( {
        classNameProp,
        tabindexProp,
        B2BPartner,
        PermissionData,
        adminorderintransitDate,
        IntransitFun,
        DeliveredTrack,
        dots,
        TrackLocation,
        NextPage,
        PrevPage,
        currentPage,
        pagination,
        DecendImage,
        AssendImage,
        TransitIdSorting,
        RtoPOrderIDSorting,
        RtoPCustomerNameSorting,
        RtoPNameSorting,
        DeliveryBoySorting,
        TransitCustomerSorting,
        TransitRecieverSorting,
        TransitProductTypeSorting,
        PendingSearchPegination

    }) => {


    return (
        <div
            className={classNameProp}
            id="transit-tab-pane"
            role="tabpanel"
            aria-labelledby="transit-tab"
            tabindex={tabindexProp}
        >
            <table>
                <tr>
                    <th> Order Id  {" "}
            <img
              src={TransitIdSorting == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => RtoPOrderIDSorting()}
            ></img>{" "} </th>
                    <th>Customer Name {" "}
            <img
              src={TransitCustomerSorting == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => RtoPCustomerNameSorting()}
            ></img>{" "} </th>
                    <th>Receiver Name {" "}
            <img
              src={TransitRecieverSorting == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => RtoPNameSorting()}
            ></img>{" "} </th>
                    <th>Product Type {" "}
            <img
              src={TransitProductTypeSorting == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => DeliveryBoySorting()}
            ></img>{" "}</th>
                    <th>Method</th>
                    {B2BPartner == "false" ? <th>Partner</th> : ""}
                    <th>Action</th>
                </tr>

                {PermissionData?.VIEW_ORDER_IN_TRANSIT ==
                    "VIEW_ORDER_IN_TRANSIT"
                    ? adminorderintransitDate &&
                    adminorderintransitDate.map((item, id) => {
                        return (
                            <tr>

                                <td>
                                    <b
                                        type="button"
                                        onClick={(e) =>
                                            IntransitFun(e, item.product_order_id)
                                        }
                                    >
                                        {" "}
                                        {item.product_order_id}
                                    </b>
                                </td>
                                <td> {item.name ? item.name : ""}</td>
                                <td>
                                    {" "}
                                    {item.receiver_name ? item.receiver_name : ""}
                                </td>
                                <td> {item.product_type} </td>
                                <td> {item.method} </td>
                                {B2BPartner == "false" ? (
                                    <td> {item.delivery_partner}</td>
                                ) : (
                                    ""
                                )}
                                <td>
                                    <div className="action-btngroup actionordergroup">
                                        <button
                                            type="button"
                                            className={`btn btn-ship ${PermissionData
                                                    ?.ALLOW_OUT_FOR_DELIVERY_ACTION ==
                                                    "ALLOW_OUT_FOR_DELIVERY_ACTION"
                                                    ? " "
                                                    : "permission_blur"
                                                }`}
                                            onClick={(e) =>
                                                PermissionData
                                                    ?.ALLOW_OUT_FOR_DELIVERY_ACTION ==
                                                    "ALLOW_OUT_FOR_DELIVERY_ACTION"
                                                    ? DeliveredTrack(
                                                        e,
                                                        item.product_order_id
                                                    )
                                                    : ""
                                            }
                                        >
                                            {" "}
                                            Out For Delivery
                                        </button>
                                        <div className="actionordergroup">
                                            {PermissionData
                                                ?.ALLOW_TRACKING_INTRANSIT_ACTION ==
                                                "ALLOW_TRACKING_INTRANSIT_ACTION" ? (
                                                <button className="actionordermenu">
                                                    <img
                                                        src={dots}
                                                        alt="img"
                                                        onClick={(e) =>
                                                            TrackLocation(e, item)
                                                        }
                                                    />{" "}
                                                </button>
                                            ) : (
                                                ""
                                            )}

                                        </div>

                                    </div>
                                </td>
                            </tr>
                        );
                    })
                    : ""}
            </table>

            {adminorderintransitDate?.length > 0 &&
        PendingSearchPegination == false ? (
          <div className="pagination_btn">
            {pagination?.previous == null ? (
              <button className="pagination_botton permission_blur">
                &laquo; Previous
              </button>
            ) : (
              <button
                className="pagination_botton"
                onClick={(e) => PrevPage(e, "Intrasit")}
              >
                &laquo; Previous
              </button>
            )}
            <p className="paginaption_p">
            {" "}
              {pagination?.current_page} of {pagination?.num_pages}
            </p>

            {pagination?.next == null ? (
              <button className="pagination_botton permission_blur">
                Next &raquo;
              </button>
            ) : (
              <button
                className="pagination_botton"
                onClick={(e) => NextPage(e, "Intrasit")}
              >
                Next &raquo;
              </button>
            )}
          </div>
        ) : (
          ""
        )}
        </div>
    )
}

export default InTransit