const ReadyToPickup = ({
  Accesspage,
  classNameProp,
  tabindexProp,
  Is_delivery_boy,
  PermissionData,
  adminorderreadyforpickupdata,
  IntransitFun,
  showAddressFun,
  activeButton,
  PickupTrack,
  ReceivedAtHubTrack,
  adminorderpickupdata,
  NextPage,
  PrevPage,
  currentPage,
  pagination,
  DateSorting,
  RtoPOrderIDSorting,
  IdOrder,
  DateOrder,
  RtoPNameSorting,
  NameOrder,
  RtoPCustomerNameSorting,
  CustomerNameOrder,
  DecendImage,
  AssendImage,
  DeliveryBoySorting,
  SortingDeliveryBoy,
  PendingSearchPegination,
  PickupIdOrder,
  PickupDelivery,
  PickupCustomerName,
  PickupRecieverName,
}) => {
  return (
    <div
      className={classNameProp}
      id="readyforpickup-tab-pane"
      role="tabpanel"
      aria-labelledby="readyforpickup-tab"
      tabindex={tabindexProp}
    >
      <table>
        <tr>
          {Is_delivery_boy !== "true" ? "" : <th>Date</th>}
          <th>
            {" "}
            Order Id{" "}
            <img
              src={Accesspage == "#ready_for_pickup" ? IdOrder == "dsc" ? DecendImage : AssendImage : PickupIdOrder == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => RtoPOrderIDSorting()}
            ></img>{" "}
          </th>
          {Is_delivery_boy !== "true" ? (
            <th>
              Delivery Boy Name{" "}
              <img
                src={Accesspage == "#ready_for_pickup" ? SortingDeliveryBoy == "dsc" ? DecendImage : AssendImage : PickupDelivery == "dsc" ? DecendImage : AssendImage}
                alt="Order Image"
                width="10"
                height="10"
                marginLeft="5px"
                onClick={(e) => DeliveryBoySorting()}
              ></img>{" "}
            </th>
          ) : (
            <th>Picked Number</th>
          )}
          <th>
            Customer Name{" "}
            <img
              src={Accesspage == "#ready_for_pickup" ? CustomerNameOrder == "dsc" ? DecendImage : AssendImage : PickupCustomerName == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => RtoPCustomerNameSorting()}
            ></img>{" "}
          </th>
          {Is_delivery_boy !== "true" ? (
            <th style={{ textAlign: "center" }}>
              Receiver Name{" "}
              <img
                src={Accesspage == "#ready_for_pickup" ? NameOrder == "dsc" ? DecendImage : AssendImage : PickupRecieverName == "dsc" ? DecendImage : AssendImage}
                alt="Order Image"
                width="10"
                height="10"
                marginLeft="5px"
                onClick={(e) => RtoPNameSorting()}
              ></img>{" "}
            </th>
          ) : (
            <th style={{ textAlign: "center" }}>
              Pickedup Name
            </th>
          )}
          <th>Method</th>
          <th>Pickedup Address</th>
          <th>Action</th>
        </tr>

        {Accesspage == "#ready_for_pickup"
          ? PermissionData?.VIEW_ORDER_READY_TO_PICKUP ==
            "VIEW_ORDER_READY_TO_PICKUP"
            ? adminorderreadyforpickupdata &&
            adminorderreadyforpickupdata?.map((item, id) => {
              return (
                <tr>
                  {Is_delivery_boy !== "true" ? (
                    ""
                  ) : (
                    <td>{new Date(item?.date_time)?.toLocaleDateString()}</td>
                  )}
                  <td>
                    <b
                      type="button"
                      onClick={(e) => IntransitFun(e, item.product_order_id)}
                    >
                      {" "}
                      {item.product_order_id}
                    </b>
                  </td>
                  {Is_delivery_boy !== "true" ? (
                    <td>{item.delivery_boy ? item.delivery_boy : ""}</td>
                  ) : (
                    <td>
                      {item?.address?.phone_number
                        ? item?.address?.phone_number
                        : ""}
                    </td>
                  )}
                  <td>{item.name ? item.name : ""}</td>

                  {Is_delivery_boy !== "true" ? (
                    <td style={{ textAlign: "center" }}>
                      {item.receiver_name ? item.receiver_name : ""}
                    </td>
                  ) : (
                    <td style={{ textAlign: "center" }}>
                      {item?.address?.name ? item?.address?.name : ""}
                    </td>
                  )}
                  <td>{item.method}</td>

                  <td>
                    {item?.product_order_id != activeButton
                      ? item?.address?.address.slice(0, 10)
                      : item?.address?.address.slice(0, 10)}
                    <span
                      onClick={(e) => showAddressFun(e, item)}
                      role="button"
                      style={{
                        color: "#faad14",
                        fontWeight: "400",
                        fontSize: "13px",
                      }}
                    >
                      {item?.product_order_id != activeButton
                        ? "..more"
                        : "..less"}
                    </span>

                    <span className="order-btn text-primary" role="button">
                      {item?.product_order_id == activeButton && (
                        <div className="dropdown">
                          <ul className=" address_all ">
                            <li className="text-dark text-nowrap">
                              {`${item?.address?.address}, ${item?.address?.city}, ${item?.address?.pincode}, ${item?.address?.state}`}
                            </li>
                          </ul>
                        </div>
                      )}
                    </span>
                  </td>
                  <td>
                    <div className="action-btngroup">
                      <button
                        type="button"
                        className={`btn btn-ship ${PermissionData?.ALLOW_READY_TO_PICKUP_ACTION ==
                          "ALLOW_READY_TO_PICKUP_ACTION"
                          ? " "
                          : "permission_blur"
                          }`}
                        onClick={(e) =>
                          PermissionData?.ALLOW_READY_TO_PICKUP_ACTION ==
                            "ALLOW_READY_TO_PICKUP_ACTION"
                            ? PickupTrack(e, item.product_order_id)
                            : ""
                        }
                      >
                        Picked Up
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })
            : ""
          : PermissionData?.VIEW_ORDER_PICKUP == "VIEW_ORDER_PICKUP"
            ? adminorderpickupdata &&
            adminorderpickupdata?.map((item, id) => {
              return (
                <tr>
                  {Is_delivery_boy !== "true" ? (
                    ""
                  ) : (
                    <td>{new Date(item?.date_time)?.toLocaleDateString()}</td>
                  )}
                  <td>
                    <b
                      type="button"
                      onClick={(e) => IntransitFun(e, item.product_order_id)}
                    >
                      {" "}
                      {item.product_order_id}
                    </b>
                  </td>
                  {Is_delivery_boy !== "true" ? (
                    <td>{item.delivery_boy ? item.delivery_boy : ""}</td>
                  ) : (
                    <td>
                      {item?.address?.phone_number
                        ? item?.address?.phone_number
                        : ""}
                    </td>
                  )}
                  <td>{item.name ? item.name : ""}</td>

                  {Is_delivery_boy !== "true" ? (
                    <td style={{ textAlign: "center" }}>
                      {item.receiver_name ? item.receiver_name : ""}
                    </td>
                  ) : (
                    <td style={{ textAlign: "center" }}>
                      {item.address.name ? item.address.name : ""}
                    </td>
                  )}
                  <td>{item.method}</td>

                  <td>
                    {item?.product_order_id != activeButton
                      ? item?.address?.address.slice(0, 10)
                      : item?.address?.address.slice(0, 10)}
                    <span
                      onClick={(e) => showAddressFun(e, item)}
                      role="button"
                      style={{
                        color: "#faad14",
                        fontWeight: "400",
                        fontSize: "13px",
                      }}
                    >
                      {item?.product_order_id != activeButton
                        ? "..more"
                        : "..less"}
                    </span>

                    <span className="order-btn text-primary" role="button">
                      {item?.product_order_id == activeButton && (
                        <div className="dropdown">
                          <ul className=" address_all ">
                            <li className="text-dark text-nowrap">
                              {`${item?.address?.address}, ${item?.address?.city}, ${item?.address?.pincode}, ${item?.address?.state}`}
                            </li>
                          </ul>
                        </div>
                      )}
                    </span>
                  </td>
                  <td>
                    <div className="action-btngroup">
                      <button
                        type="button"
                        className={`btn btn-ship ${PermissionData?.ALLOW_PICKUP_ACTION ==
                          "ALLOW_PICKUP_ACTION"
                          ? " "
                          : "permission_blur"
                          }`}
                        onClick={(e) =>
                          PermissionData?.ALLOW_PICKUP_ACTION ==
                            "ALLOW_PICKUP_ACTION"
                            ? ReceivedAtHubTrack(e, item.product_order_id)
                            : ""
                        }
                      >
                        Recieved At Hub
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })
            : ""}
      </table>

      {Accesspage == "#ready_for_pickup" ? (
        adminorderreadyforpickupdata?.length > 0 &&
          PendingSearchPegination == false ? (
          <div className="pagination_btn">
            {pagination?.previous == null ? (
              <button className="pagination_botton permission_blur">
                &laquo; Previous
              </button>
            ) : (
              <button
                className="pagination_botton"
                onClick={(e) => PrevPage(e, "readyPickup")}
              >
                &laquo; Previous
              </button>
            )}
            <p className="paginaption_p">
              {" "}
              {pagination?.current_page} of {pagination?.num_pages}
            </p>

            {pagination?.next == null ? (
              <button className="pagination_botton permission_blur">
                Next &raquo;
              </button>
            ) : (
              <button
                className="pagination_botton"
                onClick={(e) => NextPage(e, "readyPickup")}
              >
                Next &raquo;
              </button>
            )}
          </div>
        ) : (
          ""
        )
      ) : adminorderpickupdata?.length > 0 &&
        PendingSearchPegination == false ?
        (
          <div className="pagination_btn">
            {pagination?.previous == null ? (
              <button className="pagination_botton permission_blur">
                &laquo; Previous
              </button>
            ) : (
              <button
                className="pagination_botton"
                onClick={(e) => PrevPage(e, "Pickup")}
              >
                &laquo; Previous
              </button>
            )}
            <p className="paginaption_p">
              {" "}
              {pagination?.current_page} of {pagination?.num_pages}
            </p>

            {pagination?.next == null ? (
              <button className="pagination_botton permission_blur">
                Next &raquo;
              </button>
            ) : (
              <button
                className="pagination_botton"
                onClick={(e) => NextPage(e, "Pickup")}
              >
                Next &raquo;
              </button>
            )}
          </div>
        ) : (
          ""
        )}
    </div>
  );
};

export default ReadyToPickup;


