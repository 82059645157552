 import { reactLocalStorage } from "reactjs-localstorage";
 

// let array = {
//   VIEW_DASHBOARD_TOTAL_ORDER: "VIEW_DASHBOARD_TOTAL_ORDER",
//   VIEW_DASHBOARD_PENDING: "VIEW_DASHBOARD_PENDING",
//   VIEW_DASHBOARD_DELIVERED: "VIEW_DASHBOARD_DELIVERED",
//   VIEW_DASHBOARD_TRANSACTION: "VIEW_DASHBOARD_TRANSACTION",
//   VIEW_DASHBOARD_REVENUE: "VIEW_DASHBOARD_REVENUE",
//   VIEW_DASHBOARD_SHIPMENT_MATRIX: "VIEW_DASHBOARD_SHIPMENT_MATRIX",
//   VIEW_ACTIVE_SHIPMENT: "VIEW_ACTIVE_SHIPMENT",
//   APPLY_DASHBOARD_FILTER: "APPLY_DASHBOARD_FILTER",

//   VIEW_ORDER_PENDING: "VIEW_ORDER_PENDING",
//   VIEW_ORDER_BOOKED: "VIEW_ORDER_BOOKED",
//   VIEW_ORDER_IN_TRANSIT: "VIEW_ORDER_IN_TRANSIT",
//   VIEW_ORDER_DELIVERED: "VIEW_ORDER_DELIVERED",
//   VIEW_ORDER_RETURNS: "VIEW_ORDER_RETURNS",
//   APPLY_ORDER_FILTER: "APPLY_ORDER_FILTER",

//   ALLOW_PENDING_ACTION_APPROVE: "ALLOW_PENDING_ACTION_APPROVE",
//   UPLOAD_ORDER_CSV: "UPLOAD_ORDER_CSV",

//   DOWNLOAD_PENDING_CSV: "DOWNLOAD_PENDING_CSV",
//   DOWNLOAD_BOOKED_CSV: "DOWNLOAD_BOOKED_CSV",
//   DOWNLOAD_IN_TRANSIT_CSV: "DOWNLOAD_IN_TRANSIT_CSV",
//   DOWNLOAD_DELIVERED_CSV: "DOWNLOAD_DELIVERED_CSV",
//   DOWNLOAD_RETURNS_CSV: "DOWNLOAD_RETURNS_CSV",

//   CREATE_ORDER: "CREATE_ORDER",

//   ALLOW_BOOKED_ACTION: "ALLOW_BOOKED_ACTION",
//   ALLOW_IN_TRANSIT_ACTION: "ALLOW_IN_TRANSIT_ACTION",
//   ALLOW_DELIVERED_ACTION: "ALLOW_DELIVERED_ACTION",

//   GET_ORDER_INVOICE: "GET_ORDER_INVOICE",
//   EDIT_ORDER: "EDIT_ORDER",
//   VIEW_CALL_BUYER: "VIEW_CALL_BUYER",
//   DELETE_ORDER: "DELETE_ORDER",

//   VIEW_EMPLOYEE: "VIEW_EMPLOYEE",
//   CREATE_EMPLOYEE: "CREATE_EMPLOYEE",
//   EDIT_EMPLOYEE: "EDIT_EMPLOYEE", 
//   DELETE_EMPLOYEE:"DELETE_EMPLOYEE", //lagana hai 

//   VIEW_B2B_USER_PROFILE: "VIEW_B2B_USER_PROFILE",
//   EDIT_B2B_USER_PROFILE: "EDIT_B2B_USER_PROFILE",
//   DELETE_B2B_USER_PROFILE: "DELETE_B2B_USER_PROFILE",

//   VIEW_B2C_USER_PROFILE: "VIEW_B2C_USER_PROFILE",
//   EDIT_B2C_USER_PROFILE: "EDIT_B2C_USER_PROFILE",
//   DELETE_B2C_USER_PROFILE: "DELETE_B2C_USER_PROFILE",

//   VIEW_CATEGORY: "VIEW_CATEGORY",
//   CREATE_CATEGORY: "CREATE_CATEGORY",
//   EDIT_CATEGORY: "EDIT_CATEGORY",
//   DELETE_CATEGORY: "DELETE_CATEGORY", 

//   VIEW_B2B_FEEDBACK: "VIEW_B2B_FEEDBACK",
//   VIEW_B2C_FEEDBACK: "VIEW_B2C_FEEDBACK",
//   VIEW_B2B_TICKETS: "VIEW_B2B_TICKETS",
//   VIEW_B2B_TICKETS: "VIEW_B2B_TICKETS",

//   DISMISS_B2B_TICKETS: "DISMISS_B2B_TICKETS",
//   DISMISS_B2C_TICKETS: "DISMISS_B2C_TICKETS",

//   VIEW_COD_REMITTANCE: "VIEW_COD_REMITTANCE",
//   APPLY_COD_REMITTANCE_FILTER: "APPLY_COD_REMITTANCE_FILTER",
//   VIEW_COD_REMITTANCE_COUNT: "VIEW_COD_REMITTANCE_COUNT",
//   VIEW_INVOICES: "VIEW_INVOICES",

//   VIEW_INVOICES_COUNT: "VIEW_INVOICES_COUNT",
//   DOWNLOAD_CSV_COD_REMITTANCE: "DOWNLOAD_CSV_COD_REMITTANCE",
//   UPLOAD_CSV_COD_REMITTANCE: "UPLOAD_CSV_COD_REMITTANCE",
//   DOWNLOAD_INVOICE: "DOWNLOAD_INVOICE",

//   UPLOAD_PINCODE: "UPLOAD_PINCODE",
//   VIEW_NOTIFICATION: "VIEW_NOTIFICATION",
// };

export const PermissionData = () => {

  const permission = () => {
    let Data = sessionStorage.getItem("Permission_Data",false); 
    // let values =Data == undefined?{}:Data

    // console.log("kmjhgf", Data)

    if (Data == "undefined") {
      let array = {}; 
      return array
    }
    else {
      let array = {}; 
      array = JSON?.parse(Data);
      return array
    }
  }
  

  
  return permission();
};
