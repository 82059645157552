import React from 'react'
function Termsbannner() {
  return (
    <section className='banner-part privacy-banner'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h1>Terms and condition </h1>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Termsbannner