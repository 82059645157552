import React from 'react'

const Delivered = (
  {
    classNameProp,
    tabindexProp,
    B2BPartner,
    PermissionData,
    adminorderdeliveredData,
    IntransitFun,
    PrevPage,
    NextPage,
    pagination,
    PendingSearchPegination,
    currentPage,
    DeliveredRecievedSorting,
    DeliveredCustomerSorting,
    DeliveredProducttypeSorting,
    DeliveredIDSorting,
    DecendImage,
    AssendImage,
    RtoPOrderIDSorting,
    RtoPNameSorting,
    RtoPCustomerNameSorting,
    DeliveryBoySorting,
  }
) => {
  return (
    <div
      className={classNameProp}
      id="delivered-tab-pane"
      role="tabpanel"
      aria-labelledby="delivered-tab"
      tabindex={tabindexProp}
    >
      <table>
        <tr>
          <th>
            Order Id{" "}
            <img
              src={DeliveredIDSorting == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => RtoPOrderIDSorting()}
            ></img>{" "}
          </th>
          <th>
            Customer Name {" "}
            <img
              src={DeliveredCustomerSorting == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => RtoPCustomerNameSorting()}
            ></img>{" "}
          </th>
          <th>
            Receiver Name{" "}
            <img
              src={DeliveredRecievedSorting == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => RtoPNameSorting()}
            ></img>{" "}
          </th>
          <th>Product Type{" "}
            <img
              src={DeliveredProducttypeSorting == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => DeliveryBoySorting()}
            ></img>{" "}
          </th>
          <th>Method</th>
          {B2BPartner == "false" ? <th>Partner</th> : ""}
        </tr>

        {PermissionData?.VIEW_ORDER_DELIVERED ==
          "VIEW_ORDER_DELIVERED"
          ? adminorderdeliveredData &&
          adminorderdeliveredData.map((item, id) => {
            return (
              <tr>
                <td>
                  <b
                    type="button"
                    onClick={(e) =>
                      IntransitFun(e, item.product_order_id)
                    }
                  >
                    {" "}
                    {item.product_order_id}
                  </b>
                </td>

                <td> {item.name ? item.name : ""}</td>
                <td>
                  {" "}
                  {item.receiver_name ? item.receiver_name : ""}
                </td>
                <td> {item.product_type}</td>
                <td> {item.method}</td>
                {B2BPartner == "false" ? (
                  <td> {item.delivery_partner}</td>
                ) : (
                  ""
                )}





              </tr>
            );
          })
          : ""}
      </table>

      {adminorderdeliveredData?.length > 0 && PendingSearchPegination == false ? (
        <div className="pagination_btn">
          {pagination?.previous == null ? (
            <button className="pagination_botton permission_blur">
              &laquo; Previous
            </button>
          ) : (
            <button
              className="pagination_botton"
              onClick={(e) => PrevPage(e, "delivered")}
            >
              &laquo; Previous
            </button>
          )}
          <p className="paginaption_p">
          {" "}
              {pagination?.current_page} of {pagination?.num_pages}
          </p>

          {pagination?.next == null ? (
            <button className="pagination_botton permission_blur">
              Next &raquo;
            </button>
          ) : (
            <button
              className="pagination_botton"
              onClick={(e) => NextPage(e, "delivered")}
            >
              Next &raquo;
            </button>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default Delivered