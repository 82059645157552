import React from "react";

const Booked = ({
  classNameProp,
  tabindexProp,
  PermissionData,
  adminorderbookeddata,
  NextPage,
  PrevPage,
  currentPage,
  pagination,
  IntransitFun,
  showAddressFun,
  activeButton,
  TransitTrack,
  DecendImage,
  AssendImage,
  BookIDSorting,
  RtoPOrderIDSorting,
  BookedCustomer,
  RtoPCustomerNameSorting,
  BookedReceiver,
  RtoPNameSorting,
  PendingSearchPegination,
  BookedPackageDetail,
  DeliveryBoySorting
}) => {
  return (
    <div
      className={classNameProp}
      id="booked-tab-pane"
      role="tabpanel"
      aria-labelledby="booked-tab"
      tabindex={tabindexProp}
    >
      <table>
        <tr>
          <th> Order Id  {" "}
            <img
              src={BookIDSorting == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => RtoPOrderIDSorting()}
            ></img>{" "}</th>
          <th>Customer Name {" "}
            <img
              src={BookedCustomer == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => RtoPCustomerNameSorting()}
            ></img>{" "}
            </th>
          <th>Receiver Name {" "}
              <img
                src={BookedReceiver == "dsc" ? DecendImage : AssendImage}
                alt="Order Image"
                width="10"
                height="10"
                marginLeft="5px"
                onClick={(e) => RtoPNameSorting()}
              ></img>{" "}
              </th>
          <th style={{ textAlign: "center" }}>Package Detail   <img
              src={BookedPackageDetail == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => DeliveryBoySorting()}
            ></img>{" "}</th>
          <th>Method</th>
          <th>Pickup Address</th>
          <th>Action</th>
        </tr>
        {PermissionData?.VIEW_ORDER_BOOKED == "VIEW_ORDER_BOOKED"
          ? adminorderbookeddata &&
            adminorderbookeddata?.map((item, id) => {
              return (
                <tr>
                  <td>
                    <b
                      type="button"
                      onClick={(e) => IntransitFun(e, item.product_order_id)}
                    >
                      {" "}
                      {item.product_order_id}
                    </b>
                  </td>
                  <td>{item.name ? item.name : ""}</td>
                  <td>{item.receiver_name ? item.receiver_name : ""}</td>

                  <td style={{ textAlign: "center" }}>
                    {item.package_details}
                  </td>
                  <td>{item.method}</td>

                  <td>
                    {item?.product_order_id != activeButton
                      ? item?.address?.address.slice(0, 10)
                      : item?.address?.address.slice(0, 10)}
                    <span
                      onClick={(e) => showAddressFun(e, item)}
                      role="button"
                      style={{
                        color: "#faad14",
                        fontWeight: "400",
                        fontSize: "13px",
                      }}
                    >
                      {item?.product_order_id != activeButton
                        ? "..more"
                        : "..less"}
                    </span>

                    <span className="order-btn text-primary" role="button">
                      {item?.product_order_id == activeButton && (
                        <div className="dropdown">
                          <ul className=" address_all ">
                            <li className="text-dark text-nowrap">
                              {`${item?.address?.address}, ${item?.address?.city}, ${item?.address?.pincode}, ${item?.address?.state}`}
                            </li>
                          </ul>
                        </div>
                      )}
                    </span>
                  </td>

                  <td>
                    <div className="action-btngroup">
                      <button
                        type="button"
                        className={`btn btn-ship ${
                          PermissionData?.ALLOW_BOOKED_ACTION ==
                          "ALLOW_BOOKED_ACTION"
                            ? " "
                            : "permission_blur"
                        }`}
                        onClick={(e) =>
                          PermissionData?.ALLOW_BOOKED_ACTION ==
                          "ALLOW_BOOKED_ACTION"
                            ? TransitTrack(e, item.product_order_id)
                            : ""
                        }
                      >
                        In-Transit
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })
          : ""}
      </table>

      {adminorderbookeddata?.length > 0 &&
        PendingSearchPegination == false ? (
          <div className="pagination_btn">
            {pagination?.previous == null ? (
              <button className="pagination_botton permission_blur">
                &laquo; Previous
              </button>
            ) : (
              <button
                className="pagination_botton"
                onClick={(e) => PrevPage(e, "Booked")}
              >
                &laquo; Previous
              </button>
            )}
            <p className="paginaption_p">
            {" "}
              {pagination?.current_page} of {pagination?.num_pages}
            </p>

            {pagination?.next == null ? (
              <button className="pagination_botton permission_blur">
                Next &raquo;
              </button>
            ) : (
              <button
                className="pagination_botton"
                onClick={(e) => NextPage(e, "Booked")}
              >
                Next &raquo;
              </button>
            )} 
          </div>
        ) : (
          ""
        )}
    </div>
  );
};

export default Booked;
