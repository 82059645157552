import React from 'react'

function Chooseus() {
  return (
    <div className='chooseus-sec'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-xl-5 col-md-5'>
                    <img src="images/img5.png" alt="img" />
                </div>
                <div className='offset-xl-1 col-xl-6 col-md-7 mt-lg-0 mt-4'>
                    <h5> Why Choose <span>Nitro Xpress </span>  </h5>
                    <h3> Intelligent Solutions To Amplify Your eCom Business </h3>
                    <p>We have Intelligent Software System that makes Shipping more convenient for our users. Everything can be managed in one place, from orders to deliveries and returns. We focus to serve the end-users and provide them updates on every single step. That helps our clients as well in reducing non-deliveries.  </p> 
                    <p><b>Our goal is to build an operating system for eCommerce businesses. We provide same day, next day, and express air logistic service to over 23000 consumers and businesses.</b></p>
                </div>
            </div>        
        </div>
    </div>
  )
}

export default Chooseus