import React, { useEffect, useState } from 'react'
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useNavigate } from 'react-router-dom';
import { PermissionData } from "../../Permission";
import { useDispatch, useSelector } from 'react-redux';
import { GetSettingUserInfo } from '../../Redux/action/ApiCollection';
import LodingSpiner from '../../Components/LodingSpiner';

function BulkUploadProfile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [permissiondata, setPermissionData] = useState("");
    let IsAdminRole = sessionStorage.getItem("Admin_Role", false);
    let isEmploye_Role = sessionStorage.getItem("isEmploye", false);

    const GetSettingUserInfoData = useSelector(
        (state) => state.GetSettingUserInfoReducer.GetSettingUserInfoData
    );

    const OrderPagesLoaderTrueFalseData = useSelector(
        (state) =>
          state.OrderPagesLoaderTrueFalseReducer?.OrderPagesLoaderTrueFalseData
      );


    useEffect(()=>{
        let payload = {
            user_type: "b2b",
        };
        dispatch(GetSettingUserInfo(payload))
    },[dispatch])

    const handleNavigation = (item) => {
        navigate("/bulkuploadprofile/UserDetail", {
            state: {
                currentData: item,
            }
        })
    }


    return (
        <div>
            <Header />
            <div className="dashboard-part  ">
                <Sidebar />

                <div className="content-sec userprofile-sec">
                    <div className="title-bar">
                        <div className="left-part">
                            <h2>User Profiles </h2>
                        </div>
                    </div>

                    <ul className="userprofile-list">
                        {GetSettingUserInfoData?.data && GetSettingUserInfoData?.data?.User_info?.map((item) => {
                            return (<li>
                                <div className="part" onClick={() => handleNavigation(item)}>
                                    <div className="left">
                                        <img
                                            role="button"
                                            src="/images/user.png"
                                            alt="img"
                                        // onClick={(e) => Detail(e, item)}
                                        />
                                    </div>
                                    <div className="right">
                                        <h6>{item?.company_name}</h6>
                                    </div>
                                    {IsAdminRole == "true" || isEmploye_Role == "true" ? (
                                        <div className="user-btn">
                                            {/* <button
                                                type="button"
                                                className={`me-2 `} >
                                                <img src="/images/icon35.svg" alt="img" />
                                            </button> */}
                                            {/* <button
                                                type="button"
                                                className="" >
                                                <img src="/images/icon34.svg" alt="img" />
                                            </button> */}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <p>
                                    <a href="#">
                                        <svg
                                            width="10"
                                            height="7"
                                            viewBox="0 0 10 7"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0.655753 0.0301636C0.596654 0.045955 0.539628 0.0674639 0.529061 0.0779655C0.507632 0.0992605 4.75646 4.33752 4.84736 4.38556C4.91536 4.4215 5.08464 4.4215 5.15264 4.38556C5.24284 4.33789 9.49239 0.0992604 9.47112 0.0781793C9.46063 0.0677749 9.39957 0.0456827 9.33542 0.029094C9.23168 0.00225652 8.75108 -0.000952315 4.991 0.000195086C1.32867 0.00130359 0.748845 0.00530978 0.655753 0.0301636ZM0.0358121 0.606645C0.00174168 0.703746 0 0.84511 0 3.49946C0 6.1538 0.00174168 6.29517 0.0358121 6.39227C0.0555186 6.44841 0.0775538 6.49437 0.0847945 6.49437C0.0920157 6.49437 0.775988 5.82051 1.6047 4.99691L3.11145 3.49946L1.6047 2.002C0.775988 1.1784 0.0920157 0.504546 0.0847945 0.504546C0.0775538 0.504546 0.0555186 0.550481 0.0358121 0.606645ZM8.3953 2.002L6.88855 3.49946L8.3953 4.99691C9.22401 5.82051 9.90798 6.49437 9.91521 6.49437C9.92245 6.49437 9.94448 6.44841 9.96419 6.39227C9.99826 6.29517 10 6.1538 10 3.49946C10 0.84511 9.99826 0.703746 9.96419 0.606645C9.94448 0.550481 9.92245 0.504546 9.91521 0.504546C9.90798 0.504546 9.22401 1.1784 8.3953 2.002ZM2.00634 5.41457C1.03636 6.37858 0.516536 6.9085 0.528885 6.92075C0.539374 6.93114 0.60043 6.95323 0.664579 6.96982C0.768239 6.99664 1.2508 7 5 7C8.7492 7 9.23176 6.99664 9.33542 6.96982C9.39957 6.95323 9.46065 6.93114 9.47114 6.92073C9.4835 6.90846 8.96301 6.37791 7.98935 5.41033L6.48851 3.91882L6.02311 4.37702C5.59728 4.79629 5.54452 4.84168 5.40219 4.91121L5.24665 4.98719H5H4.75335L4.59781 4.91093C4.45597 4.84141 4.40162 4.79472 3.98088 4.38099C3.7271 4.13146 3.51573 3.9273 3.51115 3.9273C3.50658 3.9273 2.82941 4.59657 2.00634 5.41457Z"
                                                fill="#B1B1B1"
                                            />
                                        </svg>

                                        {item?.email}
                                    </a>
                                </p>
                                <p>
                                    <a href="#">
                                        <svg
                                            width="10"
                                            height="10"
                                            viewBox="0 0 10 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M4.54597 0.0219022C3.91098 0.0830125 3.34872 0.243994 2.77508 0.528915C2.2678 0.780878 1.89526 1.04837 1.47672 1.46116C1.07467 1.85767 0.785688 2.25796 0.527689 2.77566C-0.0835357 4.00216 -0.16676 5.39395 0.293613 6.69061C0.464401 7.1717 0.806641 7.77813 1.12087 8.15653C1.59059 8.72217 2.13227 9.15342 2.77508 9.47347C3.53788 9.85328 4.34825 10.0295 5.17772 9.99598C5.92263 9.96587 6.56949 9.8012 7.22854 9.47384C8.02175 9.07985 8.68123 8.49762 9.17423 7.75603C9.344 7.50067 9.59827 6.99907 9.70268 6.71362C10.0444 5.77925 10.0938 4.70409 9.83936 3.7375C9.74378 3.37436 9.65909 3.15092 9.47789 2.78398C9.22423 2.27031 8.95677 1.8964 8.54363 1.47788C7.50505 0.425781 6.00936 -0.118956 4.54597 0.0219022ZM3.27566 2.40394C3.33824 2.42722 4.28592 3.36166 4.32878 3.44236C4.39614 3.56917 4.3824 3.59246 4.02724 3.95338C3.84597 4.13759 3.68312 4.31672 3.66533 4.35146C3.59085 4.49685 3.60633 4.67184 3.70687 4.82137C4.03324 5.30675 4.89279 6.13667 5.28895 6.34889C5.37945 6.39738 5.55731 6.40184 5.66209 6.35825C5.70672 6.33967 5.85921 6.20456 6.05726 6.00808C6.38461 5.68334 6.43509 5.64855 6.54101 5.67463C6.60807 5.69114 7.56748 6.6412 7.6122 6.73537C7.67212 6.86163 7.60569 6.96701 7.29496 7.23855C6.96313 7.52851 6.59373 7.65177 6.21275 7.59962C5.70286 7.52984 4.86538 7.11441 4.25908 6.63053C4.03222 6.44947 3.47631 5.89209 3.29671 5.66562C2.89186 5.15511 2.53876 4.50542 2.41052 4.0351C2.3278 3.73178 2.36296 3.41423 2.51333 3.10633C2.59485 2.93937 2.62939 2.89485 2.85727 2.66293C2.99661 2.52114 3.12932 2.40145 3.15223 2.39696C3.17511 2.39247 3.19988 2.3876 3.20725 2.38614C3.21462 2.38467 3.2454 2.39268 3.27566 2.40394Z"
                                                fill="#B1B1B1"
                                            />
                                        </svg>
                                        {item?.phone_number}
                                    </a>
                                </p>
                            </li>)
                        })}

                    </ul>
                </div>
            </div>
            <LodingSpiner loadspiner={OrderPagesLoaderTrueFalseData} />

        </div>
        

    )
}

export default BulkUploadProfile