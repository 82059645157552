


const RTODelivered = (

  {
    classNameProp,
    tabindexProp,
    B2BPartner,
    PermissionData,
    adminorderrtodeliveredData,
    IntransitFun,
    PrevPage,
    NextPage,
    pagination,
    PendingSearchPegination,
    currentPage,
    showAddressFun,
    activeButton,
    DecendImage,
    AssendImage,
    RtoPOrderIDSorting,
    RtoPNameSorting,
    RtoPCustomerNameSorting,
    DeliveryBoySorting,
    RtoDeliverIdIDSorting,
    RtoDeliverRecievedSorting,
    RtoDeliverCustomerSorting,
    RtoDeliverProductTypeSorting,
    ShippingDateSortFunc,
    ShippingDateSorting,
  }

) => {
  return (

    <div
      className={classNameProp}
      id="rto-delivered-tab-pane"
      role="tabpanel"
      aria-labelledby="rto-delivered-tab"
      tabindex={tabindexProp}
    >
      <table>
        <tr>
          <th>Order Id{" "}
            <img
              src={RtoDeliverIdIDSorting == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => RtoPOrderIDSorting()}
            ></img>{" "} </th>

          <th>Customer Name {" "}
            <img
              src={RtoDeliverCustomerSorting == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => RtoPCustomerNameSorting()}
            ></img>{" "}</th>
          <th>Receiver Name {" "}
            <img
              src={RtoDeliverRecievedSorting == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => RtoPNameSorting()}
            ></img>{" "}</th>
          <th>Shipped Date{" "}
            <img
              src={ShippingDateSorting == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => ShippingDateSortFunc()}
            ></img>{" "}</th>
          <th>Product Type {" "}
            <img
              src={RtoDeliverProductTypeSorting == "dsc" ? DecendImage : AssendImage}
              alt="Order Image"
              width="10"
              height="10"
              marginLeft="5px"
              onClick={(e) => DeliveryBoySorting()}
            ></img>{" "}</th>
          <th>RTO Address</th>
          {B2BPartner == "false" ? <th>Partner</th> : ""}

        </tr>

        {PermissionData?.VIEW_ORDER_RTO_DELIVERED ==
          "VIEW_ORDER_RTO_DELIVERED"
          ? adminorderrtodeliveredData &&
          adminorderrtodeliveredData?.map((item, id) => {
            return (
              <tr>

                <td>
                  <b
                    type="button"
                    onClick={(e) =>
                      IntransitFun(e, item.product_order_id)
                    }
                  >
                    {" "}
                    {item.product_order_id}
                  </b>
                </td>
                <td>{item.name ? item.name : " "}</td>
                <td>
                  {item.receiver_name ? item.receiver_name : " "}
                </td>
                <td>
                  {new Date(item.date_time).toLocaleDateString(
                    "en-US",
                    {
                      month: "short",
                      year: "numeric",
                      day: "numeric",
                    }
                  )}
                </td>
                <td>{item.product_type}</td>
                <td>
                  {item?.product_order_id != activeButton
                    ? item?.address?.address.slice(0, 10)
                    : item?.address?.address.slice(0, 10)}
                  <span
                    onClick={(e) => showAddressFun(e, item)}

                    role="button"
                    style={{
                      color: "#faad14",
                      fontWeight: "400",
                      fontSize: "13px",
                    }}
                  >
                    {item?.product_order_id != activeButton
                      ? "..more"
                      : "..less"}
                  </span>

                  <span
                    className="order-btn text-primary"
                    role="button"
                  >
                    {item?.product_order_id == activeButton && (
                      <div className="dropdown">
                        <ul className=" address_all ">
                          <li className="text-dark text-nowrap">
                            {`${item?.address?.address}, ${item?.address?.city}, ${item?.address?.pincode}, ${item?.address?.state}`}
                          </li>
                        </ul>
                      </div>
                    )}
                  </span>
                </td>

                {B2BPartner == "false" ? (
                  <td>{item.delivery_partner} </td>
                ) : (
                  ""
                )}

              </tr>
            );
          })
          : ""}
      </table>

      {adminorderrtodeliveredData?.length > 0 && PendingSearchPegination == false ? (
        <div className="pagination_btn">
          {pagination?.previous == null ? (
            <button className="pagination_botton permission_blur">
              &laquo; Previous
            </button>
          ) : (
            <button
              className="pagination_botton"
              onClick={(e) => PrevPage(e, "rto_delivered")}
            >
              &laquo; Previous
            </button>
          )}
          <p className="paginaption_p">
            {" "}
            {pagination?.current_page} of {pagination?.num_pages}
          </p>

          {pagination?.next == null ? (
            <button className="pagination_botton permission_blur">
              Next &raquo;
            </button>
          ) : (
            <button
              className="pagination_botton"
              onClick={(e) => NextPage(e, "rto_delivered")}
            >
              Next &raquo;
            </button>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default RTODelivered