import React from 'react'

const Cancel = (

    {
        classNameProp,
        tabindexProp,
        PermissionData,
        adminordercancelData,
        IntransitFun,
        RebookFun,
        NextPage,
        PrevPage,
        pagination,
        RtoPOrderIDSorting,
        RtoPCustomerNameSorting,
        CancelDateSortFunc,
        DecendImage,
        AssendImage,
        RtoPNameSorting,
        DeliveryBoySorting,
        CancelIDSorting,
        CancelDateSorting,
        CancelRecievedSorting,
        CancelProductTypeSorting,
        CancelCustomerSorting,
        PendingSearchPegination
    }
) => {
    return (
        <div
            className={classNameProp}
            id="cancel-tab-pane"
            role="tabpanel"
            aria-labelledby="cancel-tab"
            tabindex={tabindexProp}
        >
            <table>
                <tr>
                    <th> Order Date{" "}
                        <img
                            src={CancelDateSorting == "dsc" ? DecendImage : AssendImage}
                            alt="Order Image"
                            width="10"
                            height="10"
                            marginLeft="5px"
                            onClick={(e) => CancelDateSortFunc()}
                        ></img>{" "} </th>
                    <th>Order Id{" "}
                        <img
                            src={CancelIDSorting == "dsc" ? DecendImage : AssendImage}
                            alt="Order Image"
                            width="10"
                            height="10"
                            marginLeft="5px"
                            onClick={(e) => RtoPOrderIDSorting()}
                        ></img>{" "}</th>

                    <th>Customer Name{" "}
                        <img
                            src={CancelCustomerSorting == "dsc" ? DecendImage : AssendImage}

                            alt="Order Image"
                            width="10"
                            height="10"
                            marginLeft="5px"
                            onClick={(e) => RtoPCustomerNameSorting()}
                        ></img>{" "}</th>
                    <th>Receiver Name{" "}
                        <img
                            src={CancelRecievedSorting == "dsc" ? DecendImage : AssendImage}
                            alt="Order Image"
                            width="10"
                            height="10"
                            marginLeft="5px"
                            onClick={(e) => RtoPNameSorting()}
                        ></img>{" "}</th>
                    <th>Method</th>
                    <th>Product Type{" "}
                        <img
                            src={CancelProductTypeSorting == "dsc" ? DecendImage : AssendImage}
                            alt="Order Image"
                            width="10"
                            height="10"
                            marginLeft="5px"
                            onClick={(e) => DeliveryBoySorting()}
                        ></img>{" "}</th>
                    <th>Action</th>
                </tr>

                {PermissionData?.VIEW_ORDER_CANCEL_DETAILS ==
                    "VIEW_ORDER_CANCEL_DETAILS"
                    ? adminordercancelData &&
                    adminordercancelData?.map((item, id) => {
                        return (
                            <tr>
                                <td>
                                    {new Date(item.date_time).toLocaleDateString(
                                        "en-US",
                                        {
                                            month: "short",
                                            year: "numeric",
                                            day: "numeric",
                                        }
                                    )}
                                </td>

                                <td>
                                    <b
                                        type="button"
                                        onClick={(e) =>
                                            IntransitFun(e, item.product_order_id)
                                        }
                                    >
                                        {" "}
                                        {item.product_order_id}
                                    </b>
                                </td>


                                <td>{item.name ? item.name : " "}</td>
                                <td>
                                    {item.receiver_name ? item.receiver_name : " "}
                                </td>
                                <td>{item.method}</td>
                                <td>{item.product_type}</td>
                                <td>
                                    <button
                                        type="button"
                                        className={`btn btn-ship  ${PermissionData?.ALLOW_REBOOK_ACTION ==
                                            "ALLOW_REBOOK_ACTION"
                                            ? " "
                                            : "permission_blur"
                                            }`}
                                        onClick={(e) =>
                                            PermissionData?.ALLOW_REBOOK_ACTION ==
                                                "ALLOW_REBOOK_ACTION"
                                                ? RebookFun(e, item)
                                                : ""
                                        }

                                    >
                                        {" "}
                                        Rebook

                                    </button>
                                </td>
                            </tr>
                        );
                    })
                    : ""}
            </table>

            {adminordercancelData?.length > 0 &&
                PendingSearchPegination == false ? (
                <div className="pagination_btn">
                    {pagination?.previous == null ? (
                        <button className="pagination_botton permission_blur">
                            &laquo; Previous
                        </button>
                    ) : (
                        <button
                            className="pagination_botton"
                            onClick={(e) => PrevPage(e, "cancel")}
                        >
                            &laquo; Previous
                        </button>
                    )}
                    <p className="paginaption_p">
                        {" "}
                        {pagination?.current_page} of {pagination?.num_pages}
                    </p>

                    {pagination?.next == null ? (
                        <button className="pagination_botton permission_blur">
                            Next &raquo;
                        </button>
                    ) : (
                        <button
                            className="pagination_botton"
                            onClick={(e) => NextPage(e, "cancel")}
                        >
                            Next &raquo;
                        </button>
                    )}
                </div>
            ) : (
                ""
            )}
        </div>
    )
}

export default Cancel