const PendingTab = ({
  classNameProp,
  tabindexProp,
  permission,
  adminorderpendingdataProp,
  DecendImage,
  AssendImage,
  DeletePending,
  IntransitFun,
  DeliveryBoy,
  PrevPage,
  NextPage,
  payloaddeliveryboyidProp,
  GetSettingDeliveryboyInfoDataProp,
  payloadorderidProp,
  currentPage,
  pagination,
  DateSorting,
  OrderSorting,
  IdOrder,
  DateOrder,
  NameSorting,
  NameOrder,
  CustomerNameSorting,
  CustomerNameOrder,
  PendingSearchPegination
}) => {
  return (
    <>
      <div
        className={classNameProp}
        id="pending-tab-pane"
        role="tabpanel"
        aria-labelledby="pending-tab"
        tabindex={tabindexProp}
      >
        <table>
          <tr>
            <th>
              {" "}
              Order Date{" "}
              <img
                src={DateOrder == "dsc" ? DecendImage : AssendImage}
                alt="Order Image"
                width="10"
                height="10"
                marginLeft="5px"
                onClick={(e) => DateSorting()}
              ></img>{" "}
            </th>
            <th>
              Order Id{" "}
              <img
                src={IdOrder == "dsc" ? DecendImage : AssendImage}
                alt="Order Image"
                width="10"
                height="10"
                marginLeft="5px"
                onClick={(e) => OrderSorting()}
              ></img>{" "}
            </th>
            <th>
              Customer Name {" "}
              <img
                src={CustomerNameOrder == "dsc" ? DecendImage : AssendImage}
                alt="Order Image"
                width="10"
                height="10"
                marginLeft="5px"
                onClick={(e) => CustomerNameSorting()}
              ></img>{" "}
            </th>
            <th>
              Receiver Name{" "}
              <img
                src={NameOrder == "dsc" ? DecendImage : AssendImage}
                alt="Order Image"
                width="10"
                height="10"
                marginLeft="5px"
                onClick={(e) => NameSorting()}
              ></img>{" "}
            </th>
            <th>Method</th>
            <th>Product Type</th>
            <th>Action</th>
            <th>Delivery Boy</th>
          </tr>

          {permission?.VIEW_ORDER_PENDING == "VIEW_ORDER_PENDING"
            ? adminorderpendingdataProp &&
              adminorderpendingdataProp?.map((item, id) => {
                return (
                  <tr>
                    <td>{item.date}</td>

                    <td>
                      <b
                        onClick={(e) =>
                          permission?.VIEW_ORDER_SUMMARY_PAGE ==
                          "VIEW_ORDER_SUMMARY_PAGE"
                            ? IntransitFun(e, item.product_order_id)
                            : ""
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        {item.product_order_id}
                      </b>
                    </td>

                    <td>{item.name ? item.name : ""}</td>
                    <td>{item.receiver_name ? item.receiver_name : ""}</td>

                    <td>{item.method}</td>
                    <td>{item.product_type}</td>

                    <td>
                      {item.payment_status == "SUCCESSFUL" ? (
                        <div className="action-btngroup">
                          <button
                            type="button"
                            className={`${
                              permission?.ALLOW_CANCEL_ACTION ==
                              "ALLOW_CANCEL_ACTION"
                                ? " "
                                : "permission_blur"
                            }`}
                            onClick={(e) =>
                              permission?.ALLOW_CANCEL_ACTION ==
                              "ALLOW_CANCEL_ACTION"
                                ? DeletePending(e, item.product_order_id)
                                : ""
                            }
                          >
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25ZM6.80954 6.98509L10.4481 12.5137L6.6175 18.2849H10.1146L12.4999 14.4138L14.875 18.2849H18.3822L14.5314 12.5137L18.2104 6.98509H14.7133L12.4999 10.5832L10.3066 6.98509H6.80954Z"
                                fill="#F14336"
                              />
                            </svg>
                          </button>
                        </div>
                      ) : (
                        <div className="action-btngroup">
                          <button
                            type="button"
                            className={`${
                              permission?.ALLOW_CANCEL_ACTION ==
                              "ALLOW_CANCEL_ACTION"
                                ? " "
                                : "permission_blur"
                            }`}
                            onClick={(e) =>
                              permission?.ALLOW_CANCEL_ACTION ==
                              "ALLOW_CANCEL_ACTION"
                                ? DeletePending(e, item.product_order_id)
                                : ""
                            }
                          >
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25ZM6.80954 6.98509L10.4481 12.5137L6.6175 18.2849H10.1146L12.4999 14.4138L14.875 18.2849H18.3822L14.5314 12.5137L18.2104 6.98509H14.7133L12.4999 10.5832L10.3066 6.98509H6.80954Z"
                                fill="#F14336"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                    </td>
                    <td>
                      {item.payment_status == "SUCCESSFUL" ? (
                        <select
                          className={`${
                            permission?.ALLOW_PENDING_ACTION_APPROVE ==
                            "ALLOW_PENDING_ACTION_APPROVE"
                              ? "form-select"
                              : "permission_blur"
                          }`}
                          value={payloaddeliveryboyidProp[2]}
                          selected
                          onChange={(e) =>
                            permission?.ALLOW_PENDING_ACTION_APPROVE ==
                            "ALLOW_PENDING_ACTION_APPROVE"
                              ? DeliveryBoy(e, item)
                              : ""
                          }
                        >
                          <option
                            value={
                              payloaddeliveryboyidProp == ""
                                ? "none"
                                : payloaddeliveryboyidProp[2]
                            }
                          >
                            {payloaddeliveryboyidProp == ""
                              ? "Select Delivery Boy"
                              : item.product_order_id ==
                                payloadorderidProp.product_order_id
                              ? payloaddeliveryboyidProp[2]
                              : "Select Delivery Boy"}
                          </option>
                          {permission?.ALLOW_PENDING_ACTION_APPROVE ==
                          "ALLOW_PENDING_ACTION_APPROVE"
                            ? GetSettingDeliveryboyInfoDataProp?.data?.delivery_boy_info?.map(
                                (item, id) => {
                                  return (
                                    <option
                                      value={[
                                        item.delivery_boy_id,
                                        item?.email,
                                        item?.name,
                                      ]}
                                    >
                                      {item?.name}
                                    </option>
                                  );
                                }
                              )
                            : ""}
                        </select>
                      ) : (
                        <select
                          className={`form-select input_filed_block`}
                          disabled
                          onChange={(e) => DeliveryBoy(e, item)}
                        >
                          <option value="none" selected disabled hidden>
                            Select Delivery Boy
                          </option>
                          {GetSettingDeliveryboyInfoDataProp?.data?.delivery_boy_info?.map(
                            (item, id) => {
                              return (
                                <option
                                  selected={item?.name == item.delivery_boy_id}
                                  value={[
                                    item.delivery_boy_id,
                                    item?.email,
                                    item?.name,
                                  ]}
                                >
                                  {item?.name}
                                </option>
                              );
                            }
                          )}
                        </select>
                      )}
                    </td>
                  </tr>
                );
              })
            : ""}
        </table>

        {adminorderpendingdataProp?.length > 0  ? (
          <div className="pagination_btn">
            {pagination?.previous == null ? (
              <button className="pagination_botton permission_blur">
                &laquo; Previous
              </button>
            ) : (
              <button
                className="pagination_botton"
                onClick={(e) => PrevPage(e, "pending")}
              >
                &laquo; Previous
              </button>
            )}
            <p className="paginaption_p">
            {" "}
              {pagination?.current_page} of {pagination?.num_pages}
            </p>

            {pagination?.next == null ? (
              <button className="pagination_botton permission_blur">
                Next &raquo;
              </button>
            ) : (
              <button
                className="pagination_botton"
                onClick={(e) => NextPage(e, "pending")}
              >
                Next &raquo;
              </button>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default PendingTab;
